import React, { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useToast } from '@mtsbank/ui-kit'
import { Payee } from '@open-api/ump/catalog-manager'
import { chargeApi } from '@root/api/ump/charge-invoice'
import { EWCharge } from '@open-api/ump/charge-invoice'
import { uuid } from '@root/utils/generateUuid'
import { authHelper } from '@root/utils/authHelper/AuthHelper'
import { Loading } from '@root/components/Loading/Loading'
import { ChargesList } from '../ChargesList'
import { PaymentForm } from '../PaymentForm'
import { ChargesEmpty } from '../ChargesEmpty'
import { ChargesForm } from '../ChargesForm/ChargesForm'
import { ChargesSettings } from '../ChargeSettings'

interface Props {
  serviceInfo: Payee
  setMdOrder: (mdOrder: string) => void
  setIsOtp: (isShowOtp: boolean) => void
}

export const ChargesPage: FC<Props> = ({ serviceInfo, setMdOrder, setIsOtp }) => {
  const { userProfile } = authHelper
  const userId = userProfile?.userId

  const [isSearchInvoices, setIsSearchInvoices] = useState(false)
  const [searchTicketId, setSearchTicketId] = useState(null)
  const [charges, setCharges] = useState<EWCharge[]>([])
  const [selectedCharge, setSelectedCharge] = useState<EWCharge>(null)
  const [isSubscriptionChargesLoading, setIsSubscriptionChargesLoading] = useState(false)
  const [isEmptyChargesList, setIsEmptyChargesList] = useState(false)
  const [isInvoiceSettingsOpen, setIsInvoiceSettingsOpen] = useState(false)

  const { toast } = useToast()

  const router = useRouter()

  const { subscription } = router.query as { subscription: string }

  const { params: paymentParams, serviceId, invoiceSearchParams } = serviceInfo

  useEffect(() => {
    if (subscription) {
      updateSearchTicketIdById()
    }
  }, [subscription])

  const updateSearchTicketIdById = () => {
    setIsSubscriptionChargesLoading(true)

    chargeApi
      .searchChargesBySubscriptionsUsingPOST('mtsmon_site', {
        requestId: uuid(),
        userId,
        serviceId,
        subscriptionId: subscription,
      })
      .then(({ data }) => {
        if (!data.errorCode) {
          setSearchTicketId(data.searchTicketId)
        } else {
          toast('error', 'Ошибка поиска счетов', data?.errorMessage || '', {
            withClose: true,
            withTimeout: true,
            timeout: 3000,
          })
          setIsSubscriptionChargesLoading(false)
        }
      })
      .catch(() => setIsSubscriptionChargesLoading(false))
  }

  const updateChargesResult = () => {
    setIsSearchInvoices(true)
    chargeApi
      .getSearchChargesResultUsingPOST('mtsmon_site', {
        requestId: uuid(),
        searchTicketId,
        userId,
        hidePaid: true,
        noFilterCharges: false,
        serviceId: serviceId === '17523' || serviceId === '17562' ? undefined : serviceId,
      })
      .then(({ data }) => {
        if (!data.errorCode) {
          if (data.charges) {
            setIsEmptyChargesList(false)
            setCharges(data.charges || [])
          } else {
            setSearchTicketId(null)
            setIsEmptyChargesList(true)
          }
        } else {
          setSearchTicketId(null)
          setIsEmptyChargesList(false)
          toast('error', 'Ошибка поиска счетов', data?.errorMessage || '', {
            withClose: true,
            withTimeout: true,
            timeout: 3000,
          })
        }
      })
      .catch(() => {
        setSearchTicketId(null)
        setIsEmptyChargesList(false)
      })
      .finally(() => {
        setIsSearchInvoices(false)
        setIsSubscriptionChargesLoading(false)
      })
  }

  useEffect(() => {
    if (searchTicketId) {
      updateChargesResult()
    }
  }, [searchTicketId, serviceId, userId])

  if (isSubscriptionChargesLoading) {
    return <Loading />
  }

  if (isInvoiceSettingsOpen) {
    return (
      <ChargesSettings
        setIsSettingsOpen={setIsInvoiceSettingsOpen}
        invoiceSearchParams={invoiceSearchParams}
        subscription={subscription}
      />
    )
  }

  if (isEmptyChargesList) {
    return <ChargesEmpty setIsSettingsOpen={setIsInvoiceSettingsOpen} />
  }

  if (selectedCharge) {
    return <PaymentForm setIsOtp={setIsOtp} setMdOrder={setMdOrder} charge={selectedCharge} serviceInfo={serviceInfo} />
  }

  if (charges?.length) {
    return (
      <ChargesList
        subscription={subscription}
        updateChargesResult={updateChargesResult}
        setSelectedCharge={setSelectedCharge}
        paymentParams={paymentParams}
        setIsSettingsOpen={setIsInvoiceSettingsOpen}
        charges={charges}
      />
    )
  }

  return (
    <ChargesForm
      setIsSearchInvoices={setIsSearchInvoices}
      isSearchInvoices={isSearchInvoices}
      serviceInfo={serviceInfo}
      isShowButton={!charges?.length}
      setSearchTicketId={setSearchTicketId}
    />
  )
}
